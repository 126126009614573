import React from "react";
import {Navigate, useLocation} from "react-router-dom";

type Props = {
    children: any;
}

export const ProtectedRoute = (props: Props) => {
    const isAuthenticated = () => {
        const accessToken = sessionStorage.getItem("accessToken");
        return !!accessToken;
    };

    if (!isAuthenticated()) {
        const location = useLocation();
        return (
            <Navigate replace to="/login" state={{redirectTo: location.pathname}}/>
        );
    }

    return props.children;
};

export default ProtectedRoute;
