import React, { Suspense, lazy } from 'react';
import ReactDOM from "react-dom/client";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import ProtectedRoute from "./components/protectedRoute";

import Loading from 'pages/loading';
import RandomColour from "./pages/random-colour";
import Layout from "./layouts/main";

const Home = lazy(() => import('pages/home'));
const ColourMatcher = lazy(() => import('pages/colour-matcher'));
const Identify = lazy(() => import('pages/identify'));
const LoginPage = lazy(() => import('pages/login'));
const LogoutPage = lazy(() => import('pages/logout'));
const Error404 = lazy(() => import('pages/error/404'));

import {isAuthenticated} from "./services/auth";

export default function App() {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading/>}>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home/>}/>
                        <Route path="/logout" element={
                            !isAuthenticated() ? <Navigate replace to="/" /> : <LogoutPage />
                        }/>
                        <Route path="/login" element={
                            isAuthenticated() ? <Navigate replace to="/" /> : <LoginPage />
                        }/>
                        <Route path="/colour-matcher" element={
                            <ColourMatcher/>
                        }/>
                        <Route path="/random-colour" element={
                            <ProtectedRoute>
                                <RandomColour/>
                            </ProtectedRoute>
                        }/>
                        <Route path="/identify" element={
                            <ProtectedRoute>
                                <Identify/>
                            </ProtectedRoute>
                        }/>
                        <Route path="*" element={<Error404/>}/>
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('app')!);
root.render(<App/>);

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {
                console.log('SW registered: ', registration);
            }).catch(registrationError => {
                console.log('SW registration failed: ', registrationError);
        });
    });
}
