import {
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
    InitiateAuthCommandInput,
    ChallengeNameType,
    RespondToAuthChallengeCommand,
    RespondToAuthChallengeCommandInput,
} from "@aws-sdk/client-cognito-identity-provider";

import config from "./config.json";

export const cognitoClient = new CognitoIdentityProviderClient({
    region: config.region,
});

export const signIn = async (username: string, password: string) => {
    const params = {
        AuthFlow: "USER_PASSWORD_AUTH",
        ClientId: config.clientId,
        AuthParameters: {
            USERNAME: username,
            PASSWORD: password,
        },
    } as InitiateAuthCommandInput;

    try {
        const command = new InitiateAuthCommand(params);
        const {AuthenticationResult, ChallengeName, ChallengeParameters, Session} = await cognitoClient.send(command);

        if (AuthenticationResult) {
            sessionStorage.setItem("idToken", AuthenticationResult.IdToken || "");
            sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || "");
            sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || "");
            return {AuthSession: AuthenticationResult};
        }

        if (ChallengeName) {
            return {ChallengeName, ChallengeParameters, Session};
        }
    } catch (error) {
        console.error("Error signing in: ", error);
        throw error;
    }
};

export const changePassword = async (
    username: string,
    password: string,
    session: string
) => {
    const params = {
        ChallengeName: ChallengeNameType.NEW_PASSWORD_REQUIRED,
        ChallengeResponses: {
            NEW_PASSWORD: password,
            USERNAME: username,
        },
        ClientId: config.clientId,
        UserPoolId: config.userPoolId,
        Session: session,
    } as unknown as RespondToAuthChallengeCommandInput;

    try {
        const client = new CognitoIdentityProviderClient({
            region: config.region,
        });
        const command = new RespondToAuthChallengeCommand(params);
        const {AuthenticationResult} = await client.send(command);

        if (AuthenticationResult) {
            sessionStorage.setItem("idToken", AuthenticationResult.IdToken || "");
            sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || "");
            sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || "");
            return {AuthSession: AuthenticationResult};
        }
    } catch (error) {
        console.error("Error changing password: ", error);
        throw error;
    }
}

export const isAuthenticated = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    return !!accessToken;
};

export const logout = () => {
    sessionStorage.removeItem("idToken");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
}
