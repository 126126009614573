import React, {useState} from "react";

import styles from './random-colour.module.scss'

export const RandomColour = () => {
    const [colour, setColour] = useState(null);
    const getRandomColour = () => {
        fetch('https://api.mhopwood.uk/v1/colours/random/1', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("idToken") || ""}`,
                'Access-Control-Allow-Origin': '*',
            },
        }).then(async response => {
            const json = await response.json();
            setColour(json.data[0].hex);
        }).catch(console.error);
    }

    return (
        <div className={styles.container} style={{ background: colour || 'transparent' }}>
            <div className={styles.label} onClick={getRandomColour}>
                {(colour || 'click').toUpperCase()}
            </div>
        </div>
    )
};

export default RandomColour;
