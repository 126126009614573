import React from 'react';
import {NavLink, Outlet} from 'react-router-dom';
import {isAuthenticated} from "../services/auth";

function Layout() {
    return (
        <div>
            <header>
                <NavLink to="/">Home</NavLink>
                { isAuthenticated() ? <NavLink to="/logout">Logout</NavLink> : <NavLink to="/login">Login</NavLink> }
            </header>
            <main>
                <Outlet />
            </main>
        </div>
    );
}

export default Layout;
